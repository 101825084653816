<template>
  <div class="paymengCmpt">
    <div style="position: absolute;right:20px;top:125px;z-index: 999;">
      <el-tooltip placement="top">
        <div slot="content">
          1.在货主确认付款阶段，等您开票的所有运单表格，按时间排序<br />2.您可以清楚的看到进度状态<br />3.每一批次运单的右侧有“继续”按钮，点击即可跳转
        </div>
        <div style="margin: 0px 20px;color: #0F5FFF;">
          <i class="el-icon-question"></i>
          <span>帮助</span>
        </div>
      </el-tooltip>
    </div>
    <!-- 搜索框 -->
    <el-form ref="searchForm" inline :model="searchForm" size="medium" label-width="105px">
      <el-form-item label="开票申请编号" prop="invoiceTaskID">
        <el-input v-model="searchForm.invoiceTaskID" placeholder="请输入开票申请编号" clearable></el-input>
      </el-form-item>
      <el-form-item label="货主单位" prop="enterpriseFullName" v-if="pagetype == 'payApply'">
        <el-input v-model="searchForm.enterpriseFullName" placeholder="请输入货主单位" clearable></el-input>
      </el-form-item>
      <el-form-item label="业务类型" prop="businessType">
        <el-select v-model="searchForm.businessType" placeholder="请选择业务类型" @change="businessChange" clearable>
          <el-option v-for="item in businessList" :key="item.Code" :label="item.Name" :value="item.Code"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="场景类型" prop="sceneType">
        <el-select v-model="searchForm.sceneType" placeholder="请选择场景类型" clearable>
          <el-option v-for="item in sceneList" :key="item.Code" :label="item.Name" :value="item.Code"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="发票类型" prop="invoiceType">
        <el-select v-model="searchForm.invoiceType" placeholder="请选择发票类型" clearable style="width: 180px;">
          <el-option v-for="item in invoiceList" :key="item.Code" :label="item.Name" :value="item.Code"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="支付方式" prop="payType">
        <el-select v-model="searchForm.payType" placeholder="请选择支付方式">
          <el-option v-for="item in payList" :key="item.Code" :label="item.Name" :value="item.Code"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="keyword" style="margin-left: 10px;">
        <div style="display: flex;">
          <el-dropdown @command="chooseDeteType" trigger="click" placement="bottom">
            <span class="el-dropdown-link">
              <div
                style="width: 90px; text-align: right;padding-right: 12px; color: #606266; cursor: pointer;white-space: nowrap;">
                {{ searchForm.deteTypeName }}<i style="margin-left: 2px;" class="el-icon-arrow-down"></i></div>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="item" v-for="item in deteTypeList" :key="item.id">{{ item.name }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-date-picker v-model="searchForm.time" type="datetimerange" start-placeholder="请选择开始时间"
            end-placeholder="请选择结束时间" value-format="yyyy-MM-dd HH:mm:ss" style="width: 280px;">
          </el-date-picker>
        </div>
      </el-form-item>
      <el-button type="primary" size="medium" icon="el-icon-search" @click="search">搜索</el-button>
      <el-button type="primary" size="medium" icon="el-icon-delete" @click="resetForm()">清空
      </el-button>
      <el-button type="primary" size="medium" icon="el-icon-download" @click="uploadClick()"
        :disabled="!tableData.length">下载税务表格</el-button>
      <!-- <el-button type="primary" size="medium" icon="el-icon-folder-delete" @click="flag.rejectModal = true"
        :disabled="!multipleSelection.length">批量拒绝请求</el-button> -->
      <el-button type="primary" size="medium" icon="el-icon-folder-checked" @click="agree('Yes')"
        :disabled="!multipleSelection.length">批量同意开票</el-button>
    </el-form>
    <!-- 主体区域 -->
    <div class="bodyArea">
      <!-- 表格 -->
      <el-table :data="tableData" v-loading="flag.tableDataLoading" @selection-change="handleSelectionChange"
        ref="multipleTable" :header-cell-style="{ color: '#666', background: '#f0f0f0' }" height="600" max-height="600">
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column fixed align="center" prop="index" label="序号" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="InvoiceTaskID" label="开票申请编号" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="InvoiceType" label="发票类型" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="billType" label="业务类型" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="TaskSceneName" label="场景类型" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="PayModeString" label="支付方式" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="DemanderName" label="货主单位" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="SupplierName" label="服务商单位" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="ApplyDatetime" label="开票申请时间" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="PayDatetime" label="确认支付时间" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="InvoicePrintDatetime" label="开票完成日期" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="WaybillCounts" label="总计运单数量" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" label="费率" width="200">
          <template slot-scope="scope">
            <p>{{ scope.row.Rate }}%</p>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="TransportCosts" label="运费" width="200">
          <template slot-scope="scope">
            <div style="color:#FF8B17;">
              /
              <!-- {{scope.row.TransportCosts | formatMoney}} -->
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="ExpensesOfTaxation" label="服务费/税费" width="200">
          <template slot-scope="scope">
            <div style="color:#FF8B17;">
              {{ scope.row.ExpensesOfTaxation | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="NeedPayMoney" label="运单总金额" width="200">
          <template slot-scope="scope">
            <div style="color:#FF8B17;">
              {{ scope.row.NeedPayMoney | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column fixed="right" align="center" label="操作" width="250">
          <template slot-scope="scope">
            <el-button v-if="scope.row.PayStatus == 2" @click="giveUpInvoice(scope.row)" type="primary" size="small">
              货主放弃付款</el-button>
            <el-button v-if="scope.row.PayStatus != 2" @click="goOn(scope.row)" type="primary" size="small">继续
            </el-button>
            <el-button type="primary" size="small" @click="openVoucherDialog(scope.row)" title="凭证">凭证</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="display:flex; align-items:center;">
        <!-- 分页 -->
        <el-pagination background class="pagination" @current-change="handleCurrentChange"
          :current-page.sync="pagination.page" :page-sizes="[10, 20, 30, 40, 50, 100]" @size-change="paginationSizeChange"
          layout="total, sizes,prev, pager, next, jumper" :total="pagination.total">
        </el-pagination>
        <!--数量金额总计-->
        <div style="display:flex; margin: 5px 0 0 30px;">
          <div style="color:rgb(255, 139, 23); white-space: nowrap;">运费总计：/</div>
          <div style="color:rgb(255, 139, 23); margin: 0 30px; white-space: nowrap;">
            服务费/税费总计：{{ PendProgress.TotalExpensesOfTaxation }}</div>
          <div style="color:rgb(255, 139, 23); white-space: nowrap;">运单总金额总计：{{ PendProgress.TotalNeedPayMoney }}</div>
        </div>
      </div>
      <!-- 返回 -->
      <div style="margin-top:10px;display:flex;justify-content:center" v-if="!pagetype">
        <el-button @click="goBack" icon="el-icon-arrow-left">返回</el-button>
      </div>
      <!-- 服务商同意货主放弃付款 -->
      <el-dialog :visible.sync="flag.showGiveUpInvoice" width="700px" center>
        <p style="margin: 8px 0">1.货主选择了【放弃付款】，并向您说了声“对不起”。本结算单将终止流程。</p>
        <p style="margin: 8px 0">2.您的每月最大可开票额度会自动补充。</p>
        <div style="text-align: center;">
          <el-button type="primary" size="mini" @click="handleGiveUpInvoice">同意</el-button>
        </div>
      </el-dialog>
      <!-- 驳回意见dialog -->
      <el-dialog width="700px" class="stepThreeRejectDialog" title="拒绝原因" :visible.sync="flag.rejectModal" append-to-body>
        <el-input type="textarea" maxlength="200" placeholder="请输入拒绝原因，限制200个汉字" v-model="rejectContent"></el-input>
        <el-checkbox-group v-model="checkFastReplyList" style="margin-top: 16px;">
          <el-checkbox :label="item.Name" v-for="item in fastReplyList" :key="item.Code" style="padding: 5px 0">
          </el-checkbox>
        </el-checkbox-group>
        <div style="display: flex; justify-content: center; margin-top: 16px;">
          <el-button size="mini" @click="flag.rejectModal = false">取消</el-button>
          <el-button type="primary" :loading="flag.reject" size="mini" @click="agree('No')">发送</el-button>
        </div>
      </el-dialog>
      <el-dialog width="900px" title="处理结果" :visible.sync="flag.resultModal" append-to-body :close-on-click-modal="false"
        :show-close="false">
        <el-table :data="resultData" key="resultTable" :header-cell-style="{ color: '#666', background: '#f0f0f0' }">
          <el-table-column align="center" prop="InvoiceTaskID" label="开票申请编号" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" label="处理结果" show-overflow-tooltip>
            <template slot-scope="scope">
              <el-tag type="success" v-if="scope.row.Isok == true">操作成功</el-tag>
              <el-tag type="danger" v-else>操作失败</el-tag>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="Msg" label="失败原因" show-overflow-tooltip>
          </el-table-column>
        </el-table>
        <div style="text-align: center;margin-top: 20px;">
          <el-button type="primary" size="mini" @click="confirmResult">确定</el-button>
        </div>
      </el-dialog>
    </div>
    <!-- 上传凭证dialog -->
    <el-dialog width="1200px" title="凭证" center :visible.sync="flag.voucherDialog" append-to-body
      v-if="flag.voucherDialog" :close-on-click-modal="false">
      <EvidenceInfo :businessID="currentInvoiceTaskItem.InvoiceTaskID">
      </EvidenceInfo>
    </el-dialog>
  </div>
</template>

<script>
import { pendProgress, giveUpInvoice, DownSupplierPayWaybills } from '@/api/waybill/pendingManage/index'
import { AgreeAll } from '@/api/waybill/waybillManage/index'
import { mapGetters, mapMutations } from 'vuex'
import { getDataDict } from '@/api/common/common'
import { getTaskScene } from '@/api/goodsOwner/goodsOwnerManage/basicInfo'
import EvidenceInfo from "@/components/businessCmpt/evidenceInfo";
export default {
  props: {
    enterpriseFullName: '', //货主单位/企业名称
    pagetype: ''
  },
  data() {
    return {
      flag: {
        tableDataLoading: false, //是否正在加载表格
        showGiveUpInvoice: false, //同意货主放弃付款dialog
        rejectModal: false, // 批量拒绝请求弹窗
        reject: false, // 拒绝loading
        resultModal: false, // 批量处理结果弹窗
        voucherDialog: false, //凭证dialog
      },
      pagination: {
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      tableData: [],
      resultData: [], // 批量同意处理结果
      multipleSelection: [], // 选中的数据
      rejectContent: '', // 拒绝原因
      checkFastReplyList: [], // 拒绝原因快捷回复
      fastReplyList: [], //快捷回复列表
      PendProgress: {}, // 获取的数据对象
      currentTaskId: null, //目前操作的是哪个开票单号
      searchForm: {
        invoiceTaskID: '', //结算任务单号
        invoiceType: '', //发票类型
        businessType: '', //业务类型
        payType: 0, // 支付方式
        enterpriseFullName: '',// 货主单位
        sceneType: '', //场景类型
        time: ['', ''], //时间筛选
        deteType: 3, //时间类型
        deteTypeName: '支付时间', //时间类型
      },
      deteTypeList: [{
        id: 0,
        name: '开票申请时间',
      }, {
        id: 1,
        name: '发票上传时间',
      },
      // {
      //   id: 2,
      //   name: '货主收票时间',
      // },
      {
        id: 3,
        name: '支付时间',
      }
        // , {
        //   id: 4,
        //   name: '导入运单时间',
        // }
      ], //时间类型
      idtype: '支付时间',
      sceneList: [], //场景类型
      businessList: [], //业务类型
      invoiceList: [], //发票类型
      payList: [{ Name: '全部', Code: 0 }, { Name: '线下支付', Code: 1 }, { Name: '线上支付', Code: 2 }], //支付方式
      currentInvoiceTaskItem: {},
    }
  },
  methods: {
    ...mapMutations(['setInvoiceTaskID', 'setStep']),
    init() {
      //获取发票类型
      getDataDict({ type: 20 }).then(res => {
        this.invoiceList = res.patterSetInfo
      })
      //获取业务类型
      getDataDict({ type: 27 }).then(res => {
        this.businessList = res.patterSetInfo
      })
    },
    // 选中表格
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 批量同意/拒绝
    agree(agreeStauts) {
      const loading = this.$loading({
        lock: true,
        text: '请求中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let params = {
        invoiceTaskIDs: this.multipleSelection.map(it => {
          return it.InvoiceTaskID
        }),
        agreeStauts,
        remark: this.rejectContent,
        quickReply: this.checkFastReplyList.join('_')
      }
      AgreeAll(params).then(res => {
        loading.close()
        this.resultData = res.data
        this.flag.resultModal = true
      }).finally(() => {
        loading.close()
      })
    },
    confirmResult() {
      this.flag.resultModal = false
      this.resetForm()
      this.$refs.multipleTable.clearSelection();
    },
    //业务类型改变
    businessChange(e) {
      this.searchForm.sceneType = ''
      getTaskScene({ TaskTypeID: e }).then(res => {
        this.sceneList = res.TaskTypeList.map(item => {
          return {
            Code: item.TaskSceneTypeID,
            Name: item.TaskSceneName,
          }
        })
      })
    },
    //选择时间类型
    chooseDeteType(e) {
      this.searchForm.deteType = e.id
      this.searchForm.deteTypeName = e.name
    },
    //搜索
    search() {
      this.pagination.page = 1
      this.pendProgress()
    },
    // 清空
    resetForm() {
      this.flag.tableDataLoading = true
      this.pagination = {
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      }
      this.searchForm = {
        invoiceTaskID: '', //结算任务单号
        invoiceType: '', //发票类型
        payType: 0, // 支付方式
        enterpriseFullName: '',
        businessType: '', //业务类型
        sceneType: '', //场景类型
        time: ['', ''], //时间筛选
        deteType: 3, //时间类型
        deteTypeName: '支付时间' //时间类型
      }
      this.pendProgress()
    },
    // 下载税务表格
    uploadClick() {
      const loading = this.$loading({
        lock: true,
        text: '下载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      if (!this.searchForm.time) this.searchForm.time = ["", ""]
      let params = {
        invoiceTaskID: this.getInvoiceTaskID,
        OptionType: 1, //0货主 1服务商
        PayStatus: 0, //支付状态
        StartDateTime: this.searchForm.time[0],
        EndDateTime: this.searchForm.time[1],
        // InvoiceTaskDateTypeEnum: this.searchForm.deteType, //时间类型
        DateType: this.searchForm.deteType, //时间类型
        InvoiceType: this.searchForm.invoiceType, //发票类型
        payModeId: this.searchForm.payType == 0 ? [] : this.searchForm.payType == 1 ? ['1', '4'] : ['2', '3'], // 支付方式 
        SceneType: this.searchForm.sceneType, //场景类型
        TaskType: this.searchForm.businessType, //业务类型
        Consignor: this.searchForm.consignor || '', //服务商名称
        InvoiceTaskID: this.searchForm.invoiceTaskID, //结算单号
        ownerEnterpriseName: this.pagetype == 'payApply' ? this.searchForm.enterpriseFullName : this.enterpriseFullName, //货主单位
        PageIndex: this.pagination.page,
        PageSize: this.pagination.pagesize,
      }
      DownSupplierPayWaybills(params, {
        responseType: "blob",
      }).catch((err) => {
        let { data } = err;
        let that = this;
        if (!data.type.includes("zip")) {
          let reader = new FileReader();
          reader.readAsText(data);
          reader.onload = function (e) {
            const { msg, title } = JSON.parse(reader.result);
            that.$message({
              message: `下载失败 ${msg || title}`,
              type: "error",
            });
            loading.close();
          };
          return;
        }
        const blob = new Blob([data]);
        let fileName = err.headers["content-disposition"]
          .split(";")[1]
          .split("=")[1]; //拆解获取文件名，
        if ("download" in document.createElement("a")) {
          // 非IE下载
          const elink = document.createElement("a");
          elink.download = fileName;
          elink.style.display = "none";
          elink.href = URL.createObjectURL(blob);
          document.body.appendChild(elink);
          elink.click();
          URL.revokeObjectURL(elink.href); // 释放URL 对象
          document.body.removeChild(elink);
        } else {
          // IE10+下载
          navigator.msSaveBlob(blob, fileName);
        }
        loading.close();
      });
    },
    //继续
    goOn(item) {
      this.setInvoiceTaskID(item.InvoiceTaskID)
      this.$router.push({ path: '/commission/makeInvoice/makeInvoice', query: { step: 3, currentStep: 5, pagetype: this.pagetype, enterpriseFullName: this.pagetype == 'payApply' ? item.DemanderName : this.enterpriseFullName } })
    },
    goBack() {
      this.$router.push('/commission/pendingManage/index')
    },
    //分页回调
    handleCurrentChange(e) {
      this.pagination.page = e
      this.pendProgress()
    },
    paginationSizeChange(e) {
      this.pagination.pagesize = e
      this.pendProgress()
    },
    //获取待办列表
    pendProgress() {
      this.flag.tableDataLoading = true
      if (!this.searchForm.time) this.searchForm.time = ["", ""]
      let params = {
        invoiceTaskID: this.getInvoiceTaskID,
        OptionType: 1, //0货主 1服务商
        PayStatus: 0, //支付状态
        StartDateTime: this.searchForm.time[0],
        EndDateTime: this.searchForm.time[1],
        // InvoiceTaskDateTypeEnum: this.searchForm.deteType, //时间类型
        DateType: this.searchForm.deteType, //时间类型
        InvoiceType: this.searchForm.invoiceType, //发票类型
        payModeId: this.searchForm.payType == 0 ? [] : this.searchForm.payType == 1 ? ['1', '4'] : ['2', '3'], // 支付方式 
        SceneType: this.searchForm.sceneType, //场景类型
        TaskType: this.searchForm.businessType, //业务类型
        Consignor: this.searchForm.consignor, //服务商名称
        InvoiceTaskID: this.searchForm.invoiceTaskID, //结算单号
        ownerEnterpriseName: this.pagetype == 'payApply' ? this.searchForm.enterpriseFullName : this.enterpriseFullName, //货主单位
        PageIndex: this.pagination.page,
        PageSize: this.pagination.pagesize,
      }
      pendProgress(params).then(res => {
        this.PendProgress = res.PendProgress || {}
        this.tableData = this.PendProgress.pendList.map((item, index) => {
          item.index = index + 1
          return item
        })
        this.pagination.total = Number(this.PendProgress.count)
      }).finally(() => {
        this.flag.tableDataLoading = false
      })
    },
    //处理同意货主放弃付款
    handleGiveUpInvoice() {
      const params = {
        taskId: this.currentTaskId
      }
      giveUpInvoice(params).then(res => {
        this.flag.showGiveUpInvoice = false
        this.pendProgress()
      })
    },
    //同意货主放弃付款面板
    giveUpInvoice(item) {
      this.currentTaskId = item.InvoiceTaskID
      this.flag.showGiveUpInvoice = true
    },
    // 点击凭证， 打开父组件中的dialog
    uploadVoucher(flag, data) {
      this.setInvoiceTaskID(data.InvoiceTaskID)
      this.$emit('uploadVoucher', flag, data)
    },
    //打开上传凭证弹窗
    openVoucherDialog(item) {
      this.flag.voucherDialog = true;
      this.currentInvoiceTaskItem = item;

    },

  },
  computed: {
    ...mapGetters(['getInvoiceTaskID'])
  },
  created() {
    this.pendProgress()
    this.init()
    //获取快捷回复列表
    getDataDict({ type: 58 }).then(res => {
      this.fastReplyList = res.patterSetInfo
    })
  },
  components: {
    EvidenceInfo,
  },
}
</script>

<style lang="scss" scoped>
.paymengCmpt {
  .bodyArea {
    .pagination {
      margin-top: 10px;
    }
  }
}
</style>