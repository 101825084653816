<template>
    <div class="supplierPendingDetailPage">
      <div class="facilityBox">

        <Payment :pagetype="'payApply'" @uploadVoucher="uploadVoucher">
        </Payment>
       
        <el-dialog width="1200px" title="线下付款凭证" center :visible.sync="dialogVisible">
          <UploadFile ref="uploadFile" @uploadVoucher="uploadVoucher"></UploadFile>
        </el-dialog>
  
  
      </div>
    </div>
  </template>
  
  <script>
  import Payment from '../pendingManage/cmpt/payment'
  
  import UploadFile from '../pendingManage/uploadFile/uploadFile'
  
  export default {
    data() {
      return {
        dialogVisible: false
      }
    },
    methods: {
      // 打开 或者 关闭 凭证dialog
      uploadVoucher(e) {
        this.dialogVisible = e
  
        this.$nextTick(function () {
          this.$refs.uploadFile.getUploadFile()
        })
  
      }
    },
    created() {
    },
    components: {
      Payment,
      UploadFile
    }
  }
  </script>
  
  <style lang="scss" scoped>
  
  </style>